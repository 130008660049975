export default class MessageTableItem {
    monitorId = 0;
    infoId = 0;
    moduleIndex = 0;
    moduleName = "";
    moduleType = "";
    psmStatus = 0;
    psmStatusName = "";
    status = 0;
    statusName = "";
    level = 0;
    ipAddress = "";
    message = "";
    messageColor = "";
    messageDatetime = "";
    orgCode = 0;
    orgCodeName = "";
    deviceId = "";
    machineLayer = "";
    group = "";
    topic = "";
    tag = "";
    productionDatetime = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
